import { useAtom } from "jotai";
import { KlumpCheckout } from "klump-react";
import { cohortAtom, formdataAtom } from "../../lib/atom";

const KlumpPaymentComponent = () => {
  const [formdata] = useAtom(formdataAtom);
  const [cohort] = useAtom(cohortAtom);
  const publicKey = process.env.REACT_APP_KLUMP_PUBLIC_KEY;

  const generateReference = () => {
    const timestamp = Date.now().toString();
    const randomNum = Math.floor(1000 + Math.random() * 9000);
    const newReference = `KLUMP-PAY-${timestamp.slice(-6)}-${randomNum}`;

    return newReference;
  };

  const data = {
    amount: 60000,
    shipping_fee: 0,
    currency: "NGN",
    redirect_url: "https://bootcamp.naomilucas.xyz",
    merchant_reference: generateReference(),
    meta_data: {
      customer: `${formdata.firstname} ${formdata.lastname}`,
      email: formdata.email,
    },
    items: [
      {
        image_url: "https://i.postimg.cc/d04wXFny/teb.jpg",
        item_url: "https://bootcamp.naomilucas.xyz",
        name: cohort?.name || "Tech Experience Bootcamp",
        unit_price: 60000,
        quantity: 1,
      },
    ],
  };

  const payWithKlump = () => {
    const payload = {
      publicKey: publicKey,
      data,
      onSuccess,
      onError,
      onLoad,
      onOpen,
      onClose,
    };

    // eslint-disable-next-line no-undef
    new Klump(payload);
  };

  const onSuccess = (data) => {
    alert(
      "Payment successful. Check your email for confirmation and next steps"
    );
    console.log("html onSuccess will be handled by the merchant");
    console.log(data);
    return data;
  };
  const onError = (data) => {
    console.log("html onError will be handled by the merchant");
    console.log(data);
  };

  const onLoad = (data) => {
    console.log("html onLoad will be handled by the merchant");
    console.log(data);
  };

  const onOpen = (data) => {
    console.log("html OnOpen will be handled by the merchant");
    console.log(data);
  };
  const onClose = (data) => {
    console.log("html onClose will be handled by the merchant");
    console.log(data);
  };

  return <KlumpCheckout onClick={payWithKlump} />;
};

export default KlumpPaymentComponent;
