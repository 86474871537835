import React, { useEffect, useState } from "react";
import {
  progressAtom,
  formdataAtom,
  isUpdatingAtom,
  sharedErrorsAtom,
} from "../../../lib/atom";
import {
  educationFocusValues,
  highestQualificationValues,
  adaptabilityValues,
  readinessValues,
  growthValues,
  feedbackValues,
} from "../../../constants/eligibilityConstants";
import axios from "axios";
import Swal from "sweetalert2";
import FileInput from "../../Form/FileInput";
import { useAtom } from "jotai";
import Radio from "../../Form/Radio";
import { useLocation, useNavigate } from "react-router-dom";
import SaveAndContinueWithBackButton from "../../Utility/SaveAndContinueWithBackButton";
import { useUpdateDatabase } from "../../../hooks/useUpdateDatabase";
import EligibilityMessage from "./EligibilityMessage";
import { useFormValidation } from "../../../hooks/useFormValidation";

const Eligibility = () => {
  const location = useLocation();
  const { validate } = useFormValidation("eligibility");
  const [formdata, setFormdata] = useAtom(formdataAtom);
  const [isUpdate] = useAtom(isUpdatingAtom);
  const { updateDatabase } = useUpdateDatabase();
  const router = useNavigate();
  const [, setProgress] = useAtom(progressAtom);
  const [errors, setErrors] = useState([]);
  const [sharedErros] = useAtom(sharedErrorsAtom);

  // Cv
  const [showCvHelpText, setShowCvHelpText] = useState(false);
  const [cv, setCv] = useState(null);

  const handleFileUpload = async (uploadedCV) => {
    const allowedTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    if (!allowedTypes.includes(uploadedCV.type)) {
      setErrors(["Invalid file type. Please select a PDF or Word file."]);
      setShowCvHelpText(true);
      return;
    }

    setCv(uploadedCV);
    await uploadFile(uploadedCV);
  };

  const handleCVHelp = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Unable to upload your CV?",
      text: "If you have any issues uploading your CV, send it as an email to teb.bootcamp@gmail.com",
      icon: "question",
      confirmButtonText: "I have sent it",
      confirmButtonColor: "#ECA239",
      preConfirm: () => {
        setFormdata({ ...formdata, hasUploadedCV: true });
      },
    });
  };

  const uploadFile = async (uploadedCV) => {
    setErrors([]);
    const fd = new FormData();
    fd.append("cv", uploadedCV);
    fd.append("email", formdata.email);
    const apiUrl = process.env.REACT_APP_BASE_URL + `/application/upload-cv`;
    try {
      await axios.post(apiUrl, fd);
      setFormdata({ ...formdata, hasUploadedCV: true });
    } catch (error) {
      alert(
        "Unable to upload CV. Please send it as an email to teb.bootcamp@gmail.com"
      );
    }
  };

  useEffect(() => {
    setProgress(4);
  }, [location.pathname]);

  const handleEligibility = async () => {
    setErrors([]);

    const errs = await validate();
    if (errs.length) {
      setErrors(errs);
      return;
    }

    // if (!formdata.hasUploadedCV) {
    //   if (!cv) {
    //     setErrors(["Please attach your CV as a PDF or Word file."]);
    //     return;
    //   } else {
    //     if (
    //       cv.type !== "application/pdf" &&
    //       cv.type !== "application/msword" &&
    //       cv.type !==
    //         "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    //     ) {
    //       setShowCvHelpText(true);
    //       setErrors(["Invalid file type. Please select a PDF or Word file."]);
    //       setCv(null);
    //       return;
    //     }
    //   }
    // }

    updateDatabase();
  };

  if (
    formdata.eligibilityStatus === "action_required" ||
    formdata.eligibilityStatus === "ineligible"
  ) {
    return <EligibilityMessage status={formdata.eligibilityStatus} />;
  }

  return (
    <div className="col-md-8 slide-col" id="education">
      <h4
        className="text-start text-uppercase fw-bold"
        style={{ color: "black", fontWeight: "bold" }}
      >
        ELIGIBILITY
      </h4>
      <form>
        <div className="mb-4">
          <label
            htmlFor="highest_qualification"
            className="form-label"
            style={{ color: "black", fontWeight: "bold" }}
          >
            What is the highest post-secondary qualification you have?
          </label>
          <Radio
            items={highestQualificationValues}
            value={formdata.highest_qualification}
            name={"highest_qualification"}
            flex={false}
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="education_focus"
            className="form-label"
            style={{ color: "black", fontWeight: "bold" }}
          >
            Which best describes the main focus of your education?
          </label>
          <Radio
            items={educationFocusValues}
            value={formdata.education_focus}
            name={"education_focus"}
            flex={false}
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="openness_to_tools"
            className="form-label"
            style={{ color: "black", fontWeight: "bold" }}
          >
            How open are you to trying new tools or approaches in getting work
            done?
          </label>
          <Radio
            items={readinessValues}
            value={formdata.openness_to_tools}
            name={"openness_to_tools"}
            flex={false}
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="work_disruption_feelings"
            className="form-label"
            style={{ color: "black", fontWeight: "bold" }}
          >
            Have you ever thought about the constant disruptions to the world of
            work? What feelings does this evoke in you?
          </label>
          <Radio
            items={adaptabilityValues}
            value={formdata.work_disruption_feelings}
            name={"work_disruption_feelings"}
            flex={false}
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="feedback_handling"
            className="form-label"
            style={{ color: "black", fontWeight: "bold" }}
          >
            How do you deal with feedback?
          </label>
          <Radio
            items={feedbackValues}
            value={formdata.feedback_handling}
            name={"feedback_handling"}
            flex={false}
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="approach_to_new_tasks"
            className="form-label"
            style={{ color: "black", fontWeight: "bold" }}
          >
            If you are asked to perform a task you have never done before, what
            would you do?
          </label>
          <Radio
            items={growthValues}
            value={formdata.approach_to_new_tasks}
            name={"approach_to_new_tasks"}
            flex={false}
          />
        </div>

        <div className="mb-4">
          {formdata.hasUploadedCV ? (
            <div className="alert alert-info">CV has been uploaded</div>
          ) : (
            <FileInput
              onChangeFunc={handleFileUpload}
              required={true}
              label={"Upload your CV (PDF or Word file) *"}
              accept={"application/*"}
            />
          )}

          {/* {showCvHelpText && ( */}
          <a href="#" className="text-info mt-2" onClick={handleCVHelp}>
            Help
          </a>
          {/* )} */}
        </div>

        <div className="mb-3 form-check text-end">
          {errors.map((error, index) => (
            <div key={index} className="alert alert-danger text-start">
              {error}
            </div>
          ))}

          {sharedErros.map((error, index) => (
            <div key={index} className="alert alert-danger text-start">
              {error}
            </div>
          ))}

          <SaveAndContinueWithBackButton
            handleComms={handleEligibility}
            action={"Save and continue"}
          />
        </div>
      </form>
    </div>
  );
};

export default Eligibility;
