import React from "react";
import KlumpPaymentComponent from "../components/Payments/KlumpPaymentComponent";

const KlumpPaymentTestPage = () => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <KlumpPaymentComponent />
    </div>
  );
};

export default KlumpPaymentTestPage;
